<template>
	<v-container v-if="$can('view', 'Role')" fluid>
		<div v-if="$can('create', 'Role')" class="text-right">
			<v-btn rounded color="primary" @click="createRole()">
				+ Create Role
			</v-btn>
		</div>

		<v-row class="mt-4">
			<v-col
				cols="12"
				md="6"
				lg="4"
				class="d-flex flex-column"
				v-for="role in roles"
				:key="role.id"
			>
				<v-card class="px-2 py-4" min-height="150" height="100%">
					<v-row class="flex d-flex align-content-start">
						<v-card-title class="py-0">
							{{ role.name }}
						</v-card-title>

						<v-spacer></v-spacer>

						<v-menu
							bottom
							left
							v-if="
								$can('view', 'Role') || $can('delete', 'Role')
							"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon v-bind="attrs" v-on="on">
									<v-icon>mdi-dots-vertical</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									v-if="$can('view', 'Role')"
									class="px-4"
									@click="updateRole(role)"
								>
									<v-list-item-title>
										View
									</v-list-item-title>
								</v-list-item>
								<v-list-item
									v-if="
										!defaultRoles.includes(role.name) &&
										$can('delete', 'Role')
									"
									class="px-4"
									@click="deleteRole(role)"
								>
									<v-list-item-title>
										Delete
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-row>

					<v-row class="flex d-flex align-content-start">
						<v-card-text
							class="
								text-subtitle-2
								grey--text
								text--darken-1
								py-2
							"
						>
							{{ role.description }}
						</v-card-text>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			roles: [],
			defaultRoles: ['ADMIN', 'MANAGER', 'CONTENT_WRITER'],
			isLoading: false,
		}
	},
	created() {
		this.getListingData()
	},
	methods: {
		getListingData() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/project/${projectId}/roles`,
			})
				.then((res) => {
					let _data = res.data.data
					this.roles = _data
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data,
						color: 'error',
					})
				})
			this.isLoading = false
		},
		createRole() {
			this.$router.push({ name: 'roles.create' })
		},
		updateRole(role) {
			this.$router.push({
				name: 'roles.edit',
				params: { id: role.id },
			})
		},
		deleteRole(role) {
			const projectId = this.$store.state.app.projectId
			this.axios
				.delete(`/project/${projectId}/roles/${role.id}`)
				.then((res) => {
					this.$snackbar.notify({
						message: res.data.message,
						color: 'success',
					})
					this.getListingData()
				})
				.catch((err) => {
					this.$snackbar.notify({
						message: err.response.data.message,
						color: 'error',
					})
				})
			this.isLoading = false
		},
	},
}
</script>
